import zoid from "zoid";

const CLASS = {
    VISIBLE: "zoid-visible",
    INVISIBLE: "zoid-invisible",
};

const EVENT = zoid.EVENT;

function isPerc(str) {
    return typeof str === "string" && /^[0-9]+%$/.test(str)
}
  
function toNum(val) {
    if (typeof val === "number") {
      return val
    }
  
    const match = val.match(/^([0-9]+)(px|%)$/)
  
    if (!match) {
      throw new Error(`Could not match css value from ${val}`)
    }
  
    return parseInt(match[1], 10)
  }
  
function toPx(val) {
    return `${toNum(val)}px`
}
  
function toCSS(val) {
    if (typeof val === "number") {
      return toPx(val)
    }
  
    return isPerc(val) ? val : toPx(val)
  }

function destroyElement(element) {
    if (element && element.parentNode) {
      element.parentNode.removeChild(element)
    }
}

window.chatSparkLoaded = false;

ChatSpark = zoid.create({
    // The html tag used to render my component
    tag: "chatspark-button",

    // The url that will be loaded in the iframe or popup, when someone includes my component on their page
    url: () => {
        return process.env.APP_URL
    },

    // The size of the component on their page. Only px and % strings are supported
    dimensions: {
        width: "200px",
        height: "500px",
    },

    autoResize: {
        height: true,
        width: false,
    },

    props: {
        chatbotId: {
            type: "string",
            required: true,
        },
        isOpen: {
            type: "boolean",
            required: false,
            default: () => false
        },
        fullScreen: {
            type: "boolean",
            required: false,
            default: () => false
        },
        helpCenter: {
            type: "boolean",
            required: false,
            default: () => false
        },
        data: {
            type: "object",
            required: false,
            default: () => {}
        },
        parentWindow: {
            type: "object",
            required: false,
            // default: () => {}
        },    
    },

    prerenderTemplate: function () {
        return null;
    },

    containerTemplate: function ({
        uid,
        frame,
        prerenderFrame,
        doc,
        props,
        event,
        dimensions
    }) {
        let { width, height } = dimensions;

        if (!frame || !prerenderFrame) {
            return;
        }

        const div = doc.createElement("div");
        div.setAttribute("id", uid);
        const style = doc.createElement("style");
        if (props.cspNonce) {
            style.setAttribute("nonce", props.cspNonce);
        }

        let display = "inline-block";
        let position = "absolute";
        let bottom = "10%";
        let right = "5%";
        let iframeWidth = "100%";
        let iframeHeight = "100%";
        let cssWidth = width;
        let cssHeight = height;

        if (props.fullScreen) {
            display = "flex";
            cssWidth = "100%";
            cssHeight = "100%";
            position = "relative";
            bottom = "0";
            right = "0";
            iframeWidth = "100%";
            iframeHeight = "100%";
        }

        style.appendChild(
            doc.createTextNode(`        
                html, body {
                    height: 100%;
                }        
                
                #${uid} {
                    display: ${display};
                    position: ${props.fullScreen ? "relative" : "fixed"};
                    width: ${cssWidth};
                    height: ${cssHeight};
                    bottom: ${props.fullScreen ? "auto" : props.marginBottom};
                    right:  ${props.fullScreen ? "auto" : "20px"};
                    z-index: 2100000000;
                }

                @media only screen and (max-width : 640px) {
                    #${uid}.chatspark-open {
                        position: fixed;
                        bottom: 0;
                        right: 0;
                    }
                }

                #${uid} > iframe {
                    display: inline-block;
                    position: ${position};
                    width: ${iframeWidth};
                    height: ${iframeHeight};
                    min-height: auto;
                    min-width: auto;
                    top: 0;
                    left: 0;
                    transition: opacity .2s ease-in-out;
                }

                #${uid} > iframe html, #${uid} > iframe html body {
                    width: 100%;
                    height: 100%;
                }

                #${uid} > iframe.${CLASS.INVISIBLE} {
                    opacity: 0;
                }

                #${uid} > iframe.${CLASS.VISIBLE} {
                    opacity: 1;
                }
            `)
        );

        div.appendChild(frame);
        div.appendChild(prerenderFrame);
        div.appendChild(style);

        prerenderFrame.classList.add(CLASS.VISIBLE);
        frame.classList.add(CLASS.INVISIBLE);

        event.on(EVENT.RENDERED, () => {
            prerenderFrame.classList.remove(CLASS.VISIBLE);
            prerenderFrame.classList.add(CLASS.INVISIBLE);

            frame.classList.remove(CLASS.INVISIBLE);
            frame.classList.add(CLASS.VISIBLE);

            setTimeout(() => {
            destroyElement(prerenderFrame);
            }, 1);
        });


        event.on(EVENT.RESIZE, ({ width: newWidth, height: newHeight }) => {
            const isMobile = window.matchMedia("(max-width: 640px)");

            div.classList.remove("chatspark-open");
            if (!props.fullScreen) {
                if (typeof newWidth === "number") {
                    div.style.width = toCSS(newWidth);
                }
        
                if (typeof newHeight === "number") {
                    div.style.height = toCSS(newHeight);
                }

                if (window.chatSparkOpen) {
                    div.style.height = "calc(80vh)";

                    if (isMobile.matches) {
                        // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
                        let vh = window.innerHeight * 0.01;
                        // Then we set the value in the --vh custom property to the root of the document
                        document.documentElement.style.setProperty('--vh', `${vh}px`);

                        div.style.width = "100vw";
                        div.style.height = "calc(var(--vh, 1vh) * 95)";

                        window?.addEventListener('resize', () => {
                            // We execute the same script as before
                            let vh = window.innerHeight * 0.01;
                            document.documentElement.style.setProperty('--vh', `${vh}px`);
                        });
                    }

                    div.classList.add("chatspark-open");
                }
            }
        });

        return div;
    },
});

window.chatSparkInstance = null;

window.openChatSparkBot = (
    chatbotId,
    isOpen = false,
    fullScreen = false,
    renderTag = 'body',
    triggerMessage = "",
    styles = {
        marginBottom: "20px",
        marginRight: "20px",
    }
) => {
    // open chatbot
    window.chatSparkInstance = ChatSpark({
        chatbotId,
        isOpen: (renderTag === "#helpcenterBot") ? true : isOpen,
        fullScreen: (renderTag === "#helpcenterBot") ? true : fullScreen,
        helpCenter: (renderTag === "#helpcenterBot") ? true : false,
        marginBottom: styles.marginBottom,
        marginRight: styles.marginRight,
        openChatSpark: function (method) {
            window.chatSparkOpen = true;
        },
        closeChatSpark: function (method) {
            window.chatSparkOpen = false;
        },
        parentWindow: {location: window.location, document: document.title},
    });
    if(renderTag === "#helpcenterBot" && Boolean(triggerMessage)) {
        setTimeout(() => {
            console.log("in production mode");
            window.chatSparkInstance.updateProps({
                postMessage: triggerMessage,
            });
        }, 2000);
    }
    window.chatSparkInstance.render(renderTag);
};

const parentElement = document.body;

parentElement?.addEventListener("click", function(event) {
    // Check if the clicked element matches the selector
    if (event.target.matches(".cs-trigger-chatbot")) {
        // Execute the desired functionality
        window.chatSparkOpen = true;
        const dataTrigger = event.target.getAttribute("data-trigger");

        window.chatSparkInstance.updateProps({
            toggle: true,
            postMessage: dataTrigger,
        });
    }
})
